import Home from "./home/Home";
import About from "./about/About";
import Blog from "./blog/Blog";
import Portfolio from "./portfolio/Portfolio";
import React from 'react';
import { Route, Routes,BrowserRouter,Switch, Router  } from 'react-router-dom';

export default function MultiPageRoutes() {
    return (
        <Routes>
            <Route exact path={'/'} element={<Home />} />
            <Route exact path={'/about'} element={<About />} />
            <Route exact path={'/portfolio'} element={<Portfolio />} />
            
            <Route exact path={'/blog'} element={<Blog />} />
           
        </Routes>
    )
}