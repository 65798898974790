import React from 'react';

import {Box} from "@mui/material";

function BlogBlock(props) {
   const { image, title, link } = props;
   return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
  <a href={link} style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box component={'img'}src={image}alt={'mockup'}height={'40vh'}marginTop={'5vh'}paddingRight={'5vh'}paddingLeft={'5vh'}/>
    <h1
      style={{
         fontSize: '1.2rem', 
         paddingTop: '1rem', 
         paddingLeft: '3rem', 
         paddingRight: '3rem',
         textAlign: 'center',
         color: 'black',
         transition: 'color 0.3s',
         width: '50vh', 
         background: 'linear-gradient(135deg, #f6d365 0%, #fda085 100%)', 
         WebkitBackgroundClip: 'text', 
         WebkitTextFillColor: 'transparent', 
      }}
    
    >
      {title}
    </h1>
  </a>
</Box>
   );
}
export default BlogBlock;
