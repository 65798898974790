import React from 'react';
import BlogBlock from "./BlogBlock";
import {Box, Grid} from "@mui/material";
import {info} from "../../info/Info";

export default function Blog({innerRef}) {
    return (
        <Box id={'blog'} ref={innerRef}>
            <Grid container display={'flex'} justifyContent={'center'}>
                {info.blog.map((project, index) => (
                   <Grid item xs={12} md={6} key={index}>
                       <BlogBlock image={project.image}  title={project.title} link={project.link}/>
                   </Grid>
                ))}
            </Grid>
        </Box>
    );
};