import self from "../img/self.jpeg"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import blog1 from "../img/blog1.png"
import blog2 from "../img/blog2.png"
import blog3 from "../img/blog3.png"
import blog4 from "../img/blog4.png"
import blog5 from "../img/blog5.png"
import blog6 from "../img/blog6.png"





export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export let singlePage = false;



export const info = {
    firstName: "Nitesh",
    lastName: "Khanal",
    initials: "nk", 
    position: "a Project Manager",
    selfPortrait: self, 
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🇳🇵',
            text: 'based in Nepal'
        },
        {
            emoji: "💼",
            text: "Project Manager at Webpoint solutions LLC "
        },
        {
            emoji: "📧",
            text: "niteshkhanal7@gmail.com"
        }
    ],
    socials: [
     
        {
            link: "https://github.com/iielus",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/nitesh-khanal-a8325b213/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://medium.com/@niteshkhanal7",
            icon: "fa fa-medium",
            label: 'medium'
        }
       
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello! I'm Nitesh. I'm a Project Manager at Webpoint Solutions LLC . I enjoy cycling and reading books. Currently I am exploring on how a blockchain system works. You should hire me!",
    skills:
        {
            proficientWith: ['scrum','Agile','wordress','PHP','WebFlow','javascript', 'react', 'git', 'github', 'figma'],
            exposedTo: ['Project Management','Wordpress development']
        }
    ,
    hobbies: [
        {
            label: 'reading',
            emoji: '📖'
        },
        {
            label: 'cycling',
            emoji: '🚲'
        },
        {
            label: 'writing',
            emoji: '✍🏻'
        },
        {
            label: 'music creation',
            emoji: '🎶'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "merokisan",
            live: "https://merokisan.com/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "#", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        },
        {
            title: "trainingpoint",
            live: "https://trainingpoint.io/",
            source: "#",
            image: mock2
        },
        
    ],
    blog: [
        {
           image: blog1,
           title: 'The Hidden Costs of Rushing Non-Priorities',
           link:'https://medium.com/@niteshkhanal7/the-hidden-costs-of-rushing-non-priorities-how-its-hurting-your-team-8c84cbd24805'
        },
        {
            image: blog2,
           title: 'The Magic Of User Stories',
           link:'https://medium.com/@niteshkhanal7/the-magic-of-user-sto-4550ba91ecd7',
        },
        {
            image: blog3,
           title: 'Comprehensive Technology Stack -Application Development in 2024',
           link:'https://medium.com/@niteshkhanal7/the-magic-of-user-sto-4550ba91ecd7',
        },
        {
            image: blog4,
            title: 'Effective Communication : Building Stronger Teams and Successful Projects',
            link:'https://medium.com/@niteshkhanal7/the-magic-of-user-sto-4550ba91ecd7',
         }
        
    ]
}